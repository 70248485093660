import httpManager from "../axios/httpManager";

export function getLeagueList  (search, pageNum, pageSize, orderBy) {
    let url = '/league/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpManager.post(url, search);
}
export function saveLeague  (league) {
    return httpManager.post('/league/save', league);
}
export function getLeagueById  (id) {
    return httpManager.get('/league/get_by_id?id=' + id + '&t=' + new Date().getTime())
}
export function updateLeague  (league) {
    return httpManager.post('/league/update', league);
}
export function getPlatForm  () {
    return httpManager.get('/get_platform?t=' + new Date().getTime());
}

export function getMatchAreaList () {
    return httpManager.get('/matcharea/list?t=' + new Date().getTime());
}
export function getByMatchAreaName (areaName) {
    return httpManager.get('/matcharea/get_by_name?areaName=' + areaName + '&t=' + new Date().getTime())
}
export function deleteMatchArea (areaName) {
    return httpManager.get('/matcharea/delete?areaName=' + areaName + '&t=' + new Date().getTime())
}
export function updateMatchArea (matcharea) {
    return httpManager.post('/matcharea/update', matcharea);
}
export function saveMatchArea (matcharea) {
    return httpManager.post('/matcharea/save', matcharea);
}